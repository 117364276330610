import React from 'react'
import Table from "../../../Components/Table/Table";
import {Link} from "react-router-dom";
import Api from "../../../helpers/Api";
import Heading from "../../../Components/Heading/Heading";
import qs from 'qs';

class Posts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortable: true,
            loading: true,
            error: null,
            columns: [
                {name: "#"},
                {name: "Name"},
                {name: "Email"},
                {name: "Subscribed"},
                {name: "Plan"},
                {name: "Enabled"},
                {name: "Login Enabled"},
                {name: "Enrollment Status"},
                {name: "Role"},
                {name: "Is Paid"},
                {name: "Actions"},
            ],
            posts: [],
            statuses:{},
            page: 1,
            limit: 20,
            total: 0,
            show: false,
        }
    }



    loadData = () => {
        let start = 0;
        let {page, limit} = this.state;
        start = (page - 1) * limit;
        Api.call("/users/count").then(total => {
            this.setState({total: total});
        });

        let {role} = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        Api.call(`/users?_sort=id:DESC&_limit=${limit}&_start=${start}&role=${role || ''}`).then(posts => {
            this.setState({posts: posts})
            this.loadEnrollmentStatuses(posts.map(post=>post.email));
        }).catch(console.log).finally(_ => {
            this.setState({loading: false});
        });
    };

    loadEnrollmentStatuses = (emails) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "61b7512f-7508-42da-9ef5-cd04d5690212");

        const raw = JSON.stringify({emails});

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://hhx6cxn4j0.execute-api.us-east-1.amazonaws.com/production/api/v1/users/status", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                this.setState({statuses: result});
            })
            .catch((error) => console.error(error));
    };


    onPaginate = (page) => {
        this.setState({page: page, loading: true}, this.loadData)
    };

    componentDidMount() {
        this.loadData();
    }

    parsePatient = (post) => {
        return [post.id, `${post.firstName} ${post.lastName}`, post.email, post.subscribed, post.paymentData?.selectedPlan?.price, post.enabled, post.loginEnabled, this.state.statuses[post.email], post.role, post.isPaid, 'actions'];
    };

    renderRow = (row, index) => {
        let columnIndex = 0;
        let patient = this.parsePatient(row);
        return <tr key={index}>
            {patient.map(column => {
                return this.renderColumn(column, columnIndex++, index, patient);
            })}</tr>
    };

    selectAll = () => {
        let allSelected = !this.state.allSelected;
        let row = this.state.row.map(patient => {
            patient.selected = allSelected;
            return patient;
        });
        this.setState({row: row, allSelected: allSelected});
    };

    onDelete = (event, index) => {
        event.preventDefault();
        if (!window.confirm('Are you sure you want to delete this item?')) {
            return;
        }
        this.setState({loading:true})
        let post = this.state.posts[index];
        Api.call(`/users/${post.id}`, "DELETE").then(response => {
        }).catch(console.log).finally(this.loadData)
    };
    onUp = (event, index) => {
        event.preventDefault();
        this.setState({loading: true})
        let post = this.state.posts[index];
        Api.call(`/users/${post.id}/move/up`, "POST").then(response => {

        }).catch(() => {

        }).finally(this.loadData)
    };

    onDown = (event, index) => {
        this.setState({loading: true})
        event.preventDefault();

        let post = this.state.posts[index];
        Api.call(`/users/${post.id}/move/down`, "POST").then(response => {

        }).catch(() => {

        }).finally(this.loadData)
    };

    renderColumn = (column, index, rowIndex, row) => {


        if (index === row.length - 1) {

            return (
                <td key={index}>
                    <ul>


                        <li>
                            <Link to="/" onClick={e => this.onUp(e, rowIndex)}>
                                <img width="16px" src="../../../up.png" alt="move up"/>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" onClick={e => this.onDown(e, rowIndex)}>
                                <img width="16px" src="../../../down.png" alt="move down"/>
                            </Link>
                        </li>


                        <li>
                            <Link to={`/app/users/${row[0]}`}>
                                <img width="16px" src="../../../edit.png" alt="edit"/>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" onClick={e => this.onDelete(e, rowIndex)}>
                                <img width="16px" src="../../../delete.png" alt="edit"/>
                            </Link>
                        </li>
                    </ul>
                </td>
            )
        }
        if (typeof column === "boolean") {
            return <td key={index}><span style={{color: column ? "green" : "red"}}>{column ? "Yes" : "No"}</span></td>
        }
        return <td key={index}>{column}</td>
    };
    renderHeaderColumn = (column, index) => {

        return (
            <th key={index}>
                {column.name}
            </th>
        );
    };



    renderLoading = () => {
        return <div className="dashboard">

            <Heading name="Users"/>

            <div>
                Loading...
            </div>
        </div>
    };

    render() {

        return (
            <div className="dashboard">

                <Heading name="Users" link={'/app/users/new'} linkName="Add New" filters={<FilterComponent value={qs.parse(window.location.search,{ignoreQueryPrefix: true}).role || ''} options={[
                    {label: 'All',value:''},
                    {label: 'Admin',value:'ROLE_ADMIN'},
                    {label: 'Deans',value:'ROLE_DEAN'},
                    {label: 'Students',value:'ROLE_STUDENT'},
                    {label: 'Users',value:'ROLE_USER'},
                    {label: 'Trustees 1',value:'ROLE_TRUSTEE_LIMITED'},
                    {label: 'Trustees 2',value:'ROLE_TRUSTEE_FULL'},
                ]} onChange={option=>{
                    window.location.href = `/app/users?role=${option.value}`
                }}/>} />


                <div>
                    <Table renderColumn={this.renderColumn} loading={this.state.loading}
                           renderRow={this.renderRow} renderHeaderColumn={this.renderHeaderColumn}
                           columns={this.state.columns} cardFooter="cardFooter" total={this.state.total}
                           currentPage={this.state.page} perPage={this.state.limit}
                           rows={this.state.posts} selected="Invite Selected" onPaginate={this.onPaginate}
                           onChangePerPage={(limit) => {
                               this.setState({limit: limit}, this.loadData);
                           }}
                    />
                </div>
            </div>
        )
    }
}

const FilterComponent = ({options, value, onChange})=>{
    return <div>
        {options.map(o=>{
            if(value === o.value){
                return <span>{o.label}</span>
            }
            return <a href="#" onClick={e=>{
            e.preventDefault()
                onChange(o)
            }
            }>{o.label}</a>
        })}
    </div>
}

export default Posts
